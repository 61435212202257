<template>
  <div id="js-loader" class="loader">
    <div class="loader-animation"></div>
  </div>
  <div class="maincontent" id="content" style="min-height: 618px">
    <div
      class="maincontentinner"
      style="opacity: 1; left: 0px; transition: all 0.5s ease 0s"
    >
      <div class="mt_wrap">
        <h1 class="ttl_1">{{ conts.title }}<span></span></h1>
        <nav class="breadcrumb_wrap">
          <ul class="breadcrumb">
            <li>
              <router-link :to="{ name: 'Dashboard' }"
                >{{ conts.breadcrumb.dashboard }}
              </router-link>
            </li>

            <li>
              <span>{{ conts.title }}</span>
            </li>
          </ul>
        </nav>
      </div>

      <div class="command_wrap">
        <div class="leftarea btn_wrap">
          <div class="btn_norm">
            <router-link to="/admin/event/create" dusk="to_add_event">
              {{ conts.create }}
            </router-link>
          </div>
        </div>
        <!--leftarea-->
        <div class="rightarea"></div>
        <!--rightarea-->
      </div>
      <!--command_wrap-->

      <Delete
        :title="'確認'"
        :message="'削除しますか？'"
        :model="conts.pagination_url"
      />

      <Spinner v-if="spinner" />
      <!--modal-->
      <SuccessMessage v-if="successMessage" />

      <div class="contentout_wrap">
        <div class="content_wrap">
          <div class="box_wrap">
            <div class="box_header">
              <h2 class="ttl_2 left m_r20">{{ conts.sub_title }}</h2>
              <div class="form_outwrap left">
                <div class="wrap wid_200px m_b0">
                  <div class="in_wrap">
                    <div class="select_wrap min">
                      <select
                        @change="statusChange($event)"
                        name="list_type_select"
                      >
                        <option value="下書き,公開">
                          {{ conts.status.select_all }}
                        </option>
                        <option value="下書き">{{ conts.status.draft }}</option>
                        <option value="公開">{{ conts.status.release }}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tool_wrap">
              <div class="bulktool">
                <div class="m_r50">
                  <span class="selectednum"></span><span>が選択済みです</span>
                </div>

                <div class="delete commandtext js-modal-open">
                  <button value="アーカイブ" class="" dusk="delete_data">
                    削除
                  </button>
                </div>
              </div>
              <div id="drawer_bg" class="show"></div>
              <div class="command_wrap_inbox">
                <div class="leftarea">
                  <div class="search_button_wrap" id="dsplanonepc">
                    <button class="button_search" @click="inputSearch()">
                      {{ conts.button.search }}<i class="fas fa-search"></i>
                    </button>
                  </div>
                  <div class="wrap m_b10">
                    <div class="seach_contents">
                      <div class="search_box reverse sp_none">
                        <div class="search_wrap wid_100per">
                          <div class="input_box">
                            <label
                              ><!--title--><input
                                type="text"
                                name="titlebody"
                                class="form_dsn"
                                id="searchInput"
                                autocomplete="off"
                                placeholder="タイトル 本文"
                                v-model="serachData.typeContent"
                                v-on:keyup.enter="inputSearch()"
                            /></label>
                          </div>
                          <div class="megamenu b_350" style="display: none">
                            <div class="in_wrap">
                              <p
                                id="megamenu_btn"
                                class="remove_btn tooltip tip-top"
                              >
                                <i class="fas fa-times"></i>
                              </p>
                              <div class="flex_wrap">
                                <table class="formtable">
                                  <tbody>
                                    <tr style="display: none">
                                      <th class="wid_20per v_m">
                                        {{ conts.search.user }}
                                      </th>
                                      <td class="ds_b">
                                        <div class="form_outwrap">
                                          <div class="wrap wid_100per m_b0">
                                            <div class="in_wrap">
                                              <div class="select_wrap">
                                                <select
                                                  name="update_user_id"
                                                  v-model="serachData.user"
                                                >
                                                  <option value="未選択">
                                                    未選択
                                                  </option>
                                                  <option value="2">
                                                    システム管理者
                                                  </option>
                                                </select>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>

                                    <tr>
                                      <th class="wid_20per v_m">
                                        {{ conts.search.status }}
                                      </th>
                                      <td class="ds_b">
                                        <div class="form_outwrap">
                                          <div class="wrap wid_100per m_b0">
                                            <div class="in_wrap">
                                              <div class="select_wrap">
                                                <select
                                                  name="status"
                                                  v-model="serachData.status"
                                                >
                                                  <option value="下書き,公開">
                                                    {{
                                                      conts.status.select_all
                                                    }}
                                                  </option>
                                                  <option value="下書き">
                                                    {{ conts.status.draft }}
                                                  </option>
                                                  <option value="公開">
                                                    {{ conts.status.release }}
                                                  </option>
                                                </select>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>

                                    <tr>
                                      <th class="wid_20per v_m">
                                        {{ conts.grid.posted_date }}
                                      </th>
                                      <td class="ds_b">
                                        <div class="form_outwrap">
                                          <div class="wrap wid_100per m_b0">
                                            <div class="in_wrap flex_wrap f_sp">
                                              <div
                                                class="
                                                  wrap
                                                  wid_49per
                                                  m_b0
                                                  time_wrap
                                                "
                                              >
                                                <label class="ttl_3"
                                                  >{{
                                                    conts.search.published_start
                                                  }}

                                                  <input
                                                    name="public_start"
                                                    type="date"
                                                    placeholder="YYYY/MM/DD "
                                                    class="publish_start"
                                                    autocomplete="off"
                                                  />
                                                </label>
                                              </div>
                                              <div
                                                class="
                                                  wrap
                                                  wid_50per
                                                  m_b0
                                                  p_r0
                                                  time_wrap
                                                "
                                              >
                                                <label class="ttl_3">
                                                  {{
                                                    conts.search.published_end
                                                  }}

                                                  <input
                                                    name="public_end"
                                                    type="date"
                                                    placeholder="YYYY/MM/DD "
                                                    class="publish_end"
                                                    autocomplete="off"
                                                  />
                                                </label>
                                              </div>
                                            </div>
                                            <div
                                              class="msg_wrap"
                                              v-if="publishedStartValidated"
                                            >
                                              <span class="each_msg"
                                                >{{
                                                  conts.search_validation
                                                    .start_date_format
                                                }}<span class="trg"></span
                                              ></span>
                                            </div>
                                            <div
                                              class="msg_wrap"
                                              v-if="publishedEndValidated"
                                            >
                                              <span class="each_msg"
                                                >{{
                                                  conts.search_validation
                                                    .end_date_format
                                                }}<span class="trg"></span
                                              ></span>
                                            </div>

                                            <div
                                              class="msg_wrap"
                                              v-if="startGreatThen"
                                            >
                                              <span class="each_msg"
                                                >{{
                                                  conts.search_validation
                                                    .start_date_limit
                                                }}<span class="trg"></span
                                              ></span>
                                            </div>
                                            <div
                                              class="msg_wrap"
                                              v-if="endLessThen"
                                            >
                                              <span class="each_msg"
                                                >{{
                                                  conts.search_validation
                                                    .end_date_limit
                                                }}<span class="trg"></span
                                              ></span>
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div class="flex_wrap f_e">
                                <div class="btn_min">
                                  <button @click="search()">
                                    {{ conts.button.search }}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="search_button">
                          <button @click="inputSearch()">
                            <i class="fas fa-search"></i>
                          </button>
                        </div>
                      </div>
                      <div class="in_wrap">
                        <div class="flex_wrap">
                          <ul class="count_list">
                            <li v-if="pagination.total > 0">
                              <span
                                >{{ conts.search.search_results }}
                                {{ pagination.total }}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--wrap-->
                </div>
                <!--leftarea-->
                <div class="rightarea">
                  <Pagination v-if="paginationShow" />
                </div>
                <!--rightarea-->
              </div>
              <!--command_wrap-->
            </div>
            <!--tool_wrap-->
            <form id="">
              <div class="scr_outwrap">
                <div class="scr_wrap">
                  <table class="tbl" v-if="getEventItems.length > 0">
                    <tbody>
                      <tr>
                        <th class="chkarea">
                          <label class="checkbox_wrap" dusk="checkall">
                            <input
                              type="checkbox"
                              name=""
                              value=""
                              class="chkbox allchk allcheckbox"
                            /><span></span>
                          </label>
                        </th>
                        <th class="wid_15per t_l">
                          {{ conts.grid.posted_date }}
                        </th>
                        <th class="wid_10per t_l">{{ conts.grid.title }}</th>
                        <th class="wid_10per t_l">{{ conts.grid.venue }}</th>
                        <th class="wid_10per t_l">
                          {{ conts.grid.number_of_persons }}
                        </th>
                        <th class="wid_15per t_l">
                          {{ conts.grid.start_date }}
                        </th>
                        <th class="wid_15per t_l">{{ conts.grid.end_date }}</th>
                        <th class="wid_10per t_l">
                          {{ conts.grid.contact_info }}
                        </th>

                        <th class="wid_15per"></th>
                      </tr>

                      <!-- <tr class="nopublic"> -->
                      <tr
                        v-for="event in getEventItems"
                        :key="event.id"
                        v-bind:class="[
                          event.status == '下書き' ? 'nopublic' : '',
                        ]"
                      >
                        <td class="t_c v_m">
                          <label class="checkbox_wrap" dusk="テスト入力">
                            <input
                              type="checkbox"
                              name="status_chg[]"
                              v-bind:value="event.id"
                              class="chkbox"
                            /><span></span>
                          </label>
                        </td>
                        <td class="">
                          <span>{{ format_date(event.created_at) }}</span>
                        </td>
                        <td class="v_m p_t15 p_b15">
                          <router-link
                            :to="{
                              name: 'Event Show',
                              params: { id: event.id },
                            }"
                          >
                            {{ event.title }}</router-link
                          >
                        </td>

                        <td class="">
                          <span>{{ event.place }}</span>
                        </td>

                        <td class="">
                          <span>{{ event.capacity }}</span>
                        </td>
                        <td class="">
                          <span>{{
                            format_date(
                              event.implementation_datetime.start_datetime
                            )
                          }}</span>
                        </td>
                        <td class="">
                          <span>{{
                            format_date(
                              event.implementation_datetime.end_datetime
                            )
                          }}</span>
                        </td>
                        <td class="">
                          <span>{{ event.contact }}</span>
                        </td>

                        <td class="t_c v_m command_area">
                          <div class="commandlist">
                            <div class="txt">
                              <router-link
                                :to="{
                                  name: 'Event Show',
                                  params: { id: event.id },
                                }"
                              >
                                {{ conts.button.show }}</router-link
                              >
                            </div>
                            <div class="txt">
                              <router-link
                                :to="{
                                  name: 'Event Edit',
                                  params: { id: event.id },
                                }"
                              >
                                {{ conts.button.update }}</router-link
                              >
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <p v-else>{{ conts.search.no_results }}</p>

                  <!--tbl-->
                </div>
                <!--scr_wrap-->
              </div>
              <!--scr_outwrap-->
            </form>

            <div class="command_wrap_inbox m_t10 m_b30">
              <div class="rightarea">
                <Pagination v-if="paginationShow" />
              </div>
              <!--rightarea-->
            </div>
            <!--command_wrap-->
          </div>
          <!--box_wrap-->
        </div>
        <!--content_wrap-->
      </div>
      <!--contentoutwrap-->
    </div>
    <!--maincontentinner-->
  </div>
</template>

<script>
import moment from "moment";
import "@/assets/control_panel/js/jq-function";
import axios from "axios";

import { mapState, mapGetters, mapActions } from "vuex";
import Delete from "../../../components/control_panel/Delete.vue";
import Spinner from "../../../components/control_panel/Spinner.vue";
import Pagination from "../../../components/control_panel/Pagination.vue";
import SuccessMessage from "../../../components/control_panel/SuccessMessage.vue";
import $ from "jquery";
import CONST from "../../../const/control_panel/event";

export default {
  name: "Event Create",
  data() {
    return {
      conts: CONST.LIST,
      eventArray: [],
      deleteId: "",
      user: {},
      serachData: {
        user: "未選択",
        type: "event",
        status: "下書き,公開",
        published_start: "",
        published_end: "",
      },
      publishedStartValidated: false,
      publishedEndValidated: false,
      startGreatThen: false,
      endLessThen: false,
      pageParam: {
        pageNo: 1,
        perPageCount: 25,
      },
      eventSearchInput: "",
      paginationShow: false,
    };
  },
  components: {
    Delete,
    Spinner,
    Pagination,
    SuccessMessage,
  },
  created() {
    this.setPaginationscreen(this.conts.pagination_url);

    /**
     * get all event
     * @author Manikandan
     *  @date 16/11/2021
     */

    this.getAllEvent({
      papeParam: this.pageParam,
      type: "event",
    });
  },
  computed: {
    ...mapState("event", ["allEvent", "pagination"]),
    ...mapState("user", ["loginUser"]),
    ...mapState("common", ["successMessage", "pagination"]),
    ...mapGetters("event", ["getEventItems"]),
    ...mapGetters("user", ["fullName"]),
    ...mapState("common", ["spinner"]),
    ...mapState("user", ["userFullName"]),
  },
  methods: {
    /**
     * get event,delete new
     * @author Manikanda
     *  @date 16/11/2021
     */
    ...mapActions("event", [
      "getAllEvent",
      "setDeleteId",
      "setFileterStatus",
      "getSearchData",
      "inputSearchData",
    ]),
    ...mapActions("user", ["login"]),
    ...mapActions("common", [
      "setSpinnerStatus",
      "setSucccessMessageStatus",
      "setPaginationscreen",
    ]),

    /**
     * date field customize
     * @author Manikandan
     *  @date 16/11/2021
     */
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYY/MM/DD");
      }
    },

    /**
     * discurd close option
     * @author Manikandan
     *  @date 16/11/2021
     */
    closeDiscard() {
      $("#confirmModal").removeClass("open");
    },
    getType(type) {
      return type ? "イベント" : "";
    },

    statusChange: function (event) {
      this.setFileterStatus({
        papeParam: this.pageParam,
        status: event.target.value,
      });

      this.serachData.status = event.target.value;
    },
    validateDate(dateString) {
      return moment(dateString, "YYYY-MM-DD", true).isValid();
    },
    setPage(page) {
      this.pageParam.pageNo = page;

      this.getAllEvent({
        papeParam: this.pageParam,
        type: "event",
      });
    },
    search() {
      this.getSearchData({
        papeParam: this.pageParam,
        searchTerms: this.serachData,
        keyword: this.eventSearchInput,
      });

      $(".megamenu").css("display", "none");
    },
    inputSearch() {
      this.inputSearchData({
        pageParam: this.pageParam,
        searchTerms: this.eventSearchInput,
        searchData: this.serachData,
      });

      $(".megamenu").css("display", "none");
    },
    
  },
  mounted() {
    setTimeout(() => {
      if (this.pagination.total > 20) {
        this.paginationShow = true;
      }
    }, 4000);

    var vueThis = this;
    /*********リンクにつける発火**** */
    $(function () {
      $(".js-modal-open").on("click", function () {
        var cntall = $(".allchk:checked").length;
        var cnt = $(".tbl input:checkbox:checked").length;

        vueThis.setDeleteId($(".tbl input:checkbox:checked").val());

        var checkLength = eval(cnt - cntall);

        if (checkLength == "1") {
          $(".modal").fadeIn();
          $("#confirmModal").addClass("open");
        }
        return false;
      });
    });

    /**
     * select all checkbox
     * @author Elavarasan
     *  @date 23/10/2021
     */
    $(function () {
      $(document).on("click", ".allcheckbox", function () {
        $(this)
          .closest("table")
          .find("tbody :checkbox")
          .prop("checked", this.checked)
          .closest("tr")
          .toggleClass("selected", this.checked);
      });
      $("tbody :checkbox").on("click", function () {
        $(this).closest("tr").toggleClass("selected", this.checked);
        $(this)
          .closest("table")
          .find(".allchk")
          .prop(
            "checked",
            $(this).closest("table").find("tbody :checkbox:checked").length ==
              $(this).closest("table").find("tbody :checkbox").length
          );
      });
    });

    /**
     * select checkbox with value
     * @author Elavarasan
     *  @date 23/10/2021
     */
    $(function () {
      $(document)
        .on("click", "input:checkbox", function () {
          vueThis.deleteId = $(this).val();

          var cntall = $(".allchk:checked").length;
          var cnt = $(".tbl input:checkbox:checked").length;

          console.log($("input:checkbox").val());
          // if()
          $(".selectednum").text(cnt - cntall + "件");
          if (cnt == 0) {
            $(".bulktool").removeClass("open");
            $(".command_wrap_inbox").removeClass("hide");
          } else {
            $(".bulktool").addClass("open");
            $(".command_wrap_inbox").addClass("hide");
          }
        })
        .trigger("change");
    });

    $(function () {
      var ua = navigator.userAgent;
      if (
        (ua.indexOf("iPhone") > 0 || ua.indexOf("Android") > 0) &&
        ua.indexOf("Mobile") > 0
      ) {
        // スマートフォン用処理
        $(function () {
          // ボックスを表示する
          $(".button_search").on("click", function (e) {
            $(".megamenu_sp").fadeIn("fast");
            $(".megamenu_sp").addClass("open");
            $("#drawer_bg").addClass("show");
            vueThis.publishedStartValidated = false;
            vueThis.publishedEndValidated = false;
          });

          // ボックス内をクリックした際ボックスを非表示にしない
          $(".megamenu_sp").on("click", function (event) {
            event.stopPropagation();
          });

          // // ボックス外をクリックした際ボックスを非表示にする
          $(document).on("click", function (e) {
            if (
              e.target.className !== "megamenu_sp" &&
              e.target.className !== "button_search"
            ) {
              $(".megamenu_sp").fadeOut("fast");
              $(".megamenu_sp").removeClass("open");
              $("#drawer_bg").removeClass("show");
              vueThis.publishedStartValidated = false;
              vueThis.publishedEndValidated = false;
            }
          });
        });
      } else {
        // PC用処理
        /*******検索のinoutモーダル*******/
        /*https://teratail.com/questions/35924*/

        $(function () {
          vueThis.publishedStartValidated = false;
          vueThis.publishedEndValidated = false;
          // ボックスを表示する
          $(".form_dsn").on("click", function (e) {
            $(".megamenu").fadeIn("fast");
          });

          // ボックス内をクリックした際ボックスを非表示にしない
          $(".megamenu").on("click", function (event) {
            event.stopPropagation();
          });

          // ボックス外をクリックした際ボックスを非表示にする
          // ボックス外をクリックした際ボックスを非表示にする
        });
      }
    });

    $(function () {
      $(document).on("change", ".publish_start", function () {
        let date = $(this).val();

        if (date == "") {
          vueThis.publishedStartValidated = false;
          vueThis.serachData.published_start = "";
          return false;
        }

        let fromDate = Date.parse(date);

        if (isNaN(fromDate)) {
          vueThis.publishedStartValidated = true;
          vueThis.serachData.published_start = "";
        }

        let dateFormat = vueThis.validateDate(date);

        if (dateFormat) {
          if (vueThis.serachData.published_end != "") {
            if (fromDate <= Date.parse(vueThis.serachData.published_end)) {
              vueThis.serachData.published_start = date;
              vueThis.startGreatThen = false;
              vueThis.publishedStartValidated = false;
            } else {
              vueThis.serachData.published_start = "";
              vueThis.startGreatThen = true;
            }
          } else {
            vueThis.serachData.published_start = date;
            vueThis.publishedStartValidated = false;
          }
        } else {
          vueThis.serachData.published_start = "";
          vueThis.publishedStartValidated = true;
        }
      });

      $(document).on("change", ".publish_end", function () {
        let date = $(this).val();

        if (date == "") {
          vueThis.publishedEndValidated = false;
          vueThis.serachData.published_end = "";
          return false;
        }

        let toDate = Date.parse(date);

        if (isNaN(toDate)) {
          vueThis.publishedEndValidated = true;
          vueThis.serachData.published_end = "";
        }

        let dateFormat = vueThis.validateDate(date);

        if (dateFormat) {
          if (vueThis.serachData.published_start != "") {
            if (toDate >= Date.parse(vueThis.serachData.published_start)) {
              vueThis.serachData.published_end = date;
              vueThis.endLessThen = false;

              return true;
            } else {
              vueThis.endLessThen = true;
              vueThis.serachData.published_end = "";
              return false;
            }
          } else {
            vueThis.serachData.published_end = date;
            vueThis.publishedEndValidated = false;
            return true;
          }
        } else {
          vueThis.publishedEndValidated = true;
          vueThis.serachData.published_end = "";
        }

        console.log(vueThis.serachData);
      });

      $(document).on("keyup", "#searchInput", function () {
        vueThis.eventSearchInput = $(this).val();
      });
    });
  },
};
</script>

<style scoped>
/* 外部CSSスタイルシートを追加する */
@import "../../../assets/control_panel/scss/main.scss";
</style>
